<template>
  <div class="setUp_page">
    <div class="header_common_box">
      <v-header :params="{ title, leftIcon: true }"></v-header>
    </div>
    <div class="content">
      <van-cell title="个人设置" is-link to="userInfo" />
      <div style="margin: 16px;">
        <van-button round block type="info" @click="logOut">退出登录</van-button>
      </div>
    </div>
  </div>
</template>

<script>
import vHeader from "@/components/header.vue";
import isWeixin from '@/utils/utils.js'

export default {
  components: {
    vHeader,
  },
  data() {
    return {
      title: this.$route.meta.title
    }
  },
  mounted:function(){
    //判断网页是什么打开
    let isWeiXin = isWeixin()
    if(isWeiXin){
      this.showHeader = false
    }
    if(this.showHeader==false){
      document.title = ''
    }
  },
  created() {
    if (!this.$store.state.common.loginInfo.access_token) {
      //this.goPage('login')
      this.$router.replace('/login')
    }
  },
  methods: {
    logOut() {
      this.$dialog.confirm({
        title: '用户退出',
        message: '确定要退出吗？',
      }).then(() => {
        this.$store.commit('common/updateState', [{ name: ['loginInfo'], value: {} }])
        localStorage.clear()
        sessionStorage.clear()
        this.goPage('login')
      }).catch(() => {
        // on cancel
      });
    }
  }
};
</script>
<style lang="less" scoped>
.setUp_page {
  .content {
    margin-top: 12px;
  }
}
</style>
